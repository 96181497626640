import React from 'react';
import PropTypes from 'prop-types';
import { Field, useForm } from 'react-final-form';
import cn from 'classnames';
import useNewItemLocalState from '../../../../hooks/useNewItemLocalState';
import { FORM_VALUES } from '../../../../utils/helpers/constants';

const leasedType = [
  { value: 'false', name: 'new_search.buy', id: 'new-search-form-buy' },
  { value: 'true', name: 'new_search.rent', id: 'new-search-form-rent' }
];

const createNewItemLeasedType = [
  { value: 'false', name: 'new_ad.sell', id: 'new-item-form-buy' },
  { value: 'true', name: 'new_ad.rent', id: 'new-item-form-rent' }
];

const NewSearchLeased = ({
  t,
  value,
  margin,
  defaultValue,
  submitOnChange = false,
  isCreateNewItemPage = false
}) => {
  const { submit, change } = useForm();
  const mapList = isCreateNewItemPage ? createNewItemLeasedType : leasedType;
  const { setFirstViewValue } = useNewItemLocalState();

  const labelClasses = isActive =>
    cn('filters__leased_type', {
      'filters__leased_type-active': isActive,
      'filters__leased_type-active_blue': isActive && isCreateNewItemPage
    });

  const submitOrNot = leasedValue => {
    if (isCreateNewItemPage) {
      setFirstViewValue(leasedValue);
      return change(FORM_VALUES.leased, leasedValue);
    }

    if (submitOnChange) {
      change('leased', leasedValue);
      submit();
    }
  };

  return (
    <div className="filters__options-wrapper">
      {mapList.map(type => (
        <div
          tabIndex={0}
          key={type.id}
          role="button"
          data-stat={type.id}
          className="filters__option"
          onClick={() => submitOrNot(type.value)}
          onKeyPress={() => submitOrNot(type.value)}
        >
          <label
            id={type.id}
            style={{ margin }}
            className={labelClasses(value === type.value)}
          >
            <Field
              type="radio"
              component="input"
              value={type.value}
              className="display-none"
              defaultValue={defaultValue}
              name={isCreateNewItemPage ? FORM_VALUES.leased : 'leased'}
            />
            {t(type.name)}
          </label>
        </div>
      ))}
    </div>
  );
};

NewSearchLeased.propTypes = {
  t: PropTypes.func,
  value: PropTypes.string,
  margin: PropTypes.string,
  defaultValue: PropTypes.string,
  submitOnChange: PropTypes.bool,
  isCreateNewItemPage: PropTypes.bool
};

export default NewSearchLeased;
