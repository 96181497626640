import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './styled';
import { convertArrayToObject } from '../../../utils/helpers/convertArrayToObject';

const buttonTypes = [
  'default',
  'edit',
  'white',
  'delete',
  'green',
  'kapitalBank',
  'link',
  'singin'
];

export const ButtonTypeMap = convertArrayToObject(buttonTypes);

export const Button = ({
  className,
  type,
  name,
  text,
  onClick,
  onKeyPress,
  loading,
  disabled,
  stat,
  cypress,
  isSmall,
  children,
  state,
  styles,
  hover
}) => (
  <StyledButton
    className={className}
    data-cy={cypress}
    data-stat={stat}
    disabled={disabled}
    isSmall={isSmall}
    state={state}
    type={type}
    name={name}
    onClick={onClick}
    onKeyPress={onKeyPress}
    styles={styles}
    hover={hover}
  >
    {loading ? (
      <></>
    ) : (
      <span>
        {children}
        {text}
      </span>
    )}
  </StyledButton>
);

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  stat: PropTypes.string,
  cypress: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isSmall: PropTypes.bool,
  state: PropTypes.oneOf(buttonTypes),
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hover: PropTypes.string
};

Button.defaultProps = {
  type: 'button',
  name: null,
  text: '',
  children: null,
  disabled: false,
  loading: false,
  isSmall: false,
  state: ButtonTypeMap.default,
  styles: {}
};
